@import "../globals";

.Credits {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;

    margin-top: 2rem;

    font-size: 1rem;
    color: $mid;

    span {
        display: flex;
        justify-content: center;
        column-gap: 0.25rem;
    }

    a {
        height: 18px;
    }

    svg {
        fill: $mid;
        transition: all ease-in-out 0.25s;
    }

    svg:hover {
        fill: $light;
    }
}

.CreditsLink {
    span {
        position: relative;
        z-index: 5;
    }
}

.CreditsLink:hover {
    color: $dark;
}

.CreditsLink::after {
    display: block;
    width: 100%;
    height: 0;
    content: "";

    padding: 0 0.125rem;

    z-index: -5;
    transform: translateY(-98%) translateX(-2px);

    border-radius: 5px;

    transition: all ease-in-out 0.5s;
}

.CreditsLink:hover::after {
    height: 1.1rem;
    background-color: $light;
}
