// Colors

$dark: #1a1a1a;
$light: #e5e5e5;
$mid: #a7a7a7;
$blue: #559ad3;
$green: #48bf84;
$yellow: #deb887; //#f3e482

// Fonts

$sans: "Outfit", sans-serif;

// Dimensions

$widthL: 280px;
