@import "globals";

* {
    padding: 0;
    margin: 0;
}

body {
    background-color: $dark;
    color: $light;
    font-family: $sans;
}

a {
    display: block;

    color: $light;
    text-decoration: none;

    transition: all ease-in-out 0.25s;
}

// Utility

.App {
    overflow: hidden;
}

.Running {
    fill: $green !important;
    color: $green !important;
}

.Pause {
    fill: $yellow !important;
    color: $yellow !important;
}
